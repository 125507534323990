import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {faCircleInfo, faEye, faEyeSlash,} from "@fortawesome/free-solid-svg-icons";

library.add(faEye, faEyeSlash, faCircleInfo);

export default function InfoButton(props) {
    return (
        <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={
                <Popover>
                    <Popover.Body>
                        {props.header ? (
                            <>
                                <h6>
                                    <b>{props.header}</b>
                                </h6>
                                <hr className="my-0 mb-2"/>
                            </>
                        ) : null}
                        {props.children}
                    </Popover.Body>
                </Popover>
            }
        >
            <FontAwesomeIcon icon="circle-info"/>
        </OverlayTrigger>
    );
}
