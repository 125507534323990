import {getPageImage} from "./App";

export default function BannerImage(props) {
    return (
        <div
            className="banner-image"
            style={{
                background: `url(${getPageImage()})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: 150,
                borderRadius: 5,
                boxShadow: "inset 0px 0px 15px 5px rgba(0, 0, 0, 0.5)",
                ...props.style,
            }}
        />
    );
}

