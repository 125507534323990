import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import BannerImage from "./BannerImage";
import InfoButton from "./InfoButton";

export default function ComponentCard(props) {
    return (
        <Card
            body
            className="pb-0"
            style={{
                border: "none",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                ...props.style,
            }}
        >
            <BannerImage/>
            <h4 className="mt-3" style={{textAlign: "left"}}>
                <Row className="text-start">
                    <Col>
                        <b>{props.title}</b>
                    </Col>
                    <Col xs={"auto"}>
                        {props.helpContent ? (
                            <InfoButton header={props.title}>{props.helpContent}</InfoButton>
                        ) : null}
                    </Col>
                </Row>
            </h4>
            <hr className="mt-0"/>
            {props.children}
        </Card>
    );
}
