import React, {useEffect, useState} from "react";
import {auth} from "../firebase";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useLocation, useNavigate} from "react-router-dom";
import CheckoutPage from "./CheckoutPage";
import BannerImage from "./BannerImage";
import {Card} from "react-bootstrap";
import FeedbackAlert from "./FeedbackAlert";
import {getUserFromEmail} from "../functions/getUser";
import {postWithCredentials} from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function CreateSubscription() {
    const [clientSecret, setClientSecret] = useState("");
    const [feedback, setFeedback] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const subscribe = async () => {
            const userId = auth.currentUser.uid;
            const userEmail = auth.currentUser.email;

            const user = await getUserFromEmail(userEmail)
            if (user.FPP === 1) {
                navigate("/dashboard");
            }

            const queryParams = new URLSearchParams(location.search);
            const id = queryParams.get("class_id");
            if (!id) {
                setFeedback({
                    variant: "warning",
                    message: "You have no items to check out. Redirecting...",
                    loading: true,
                });
                setTimeout(() => {
                    navigate("/show-service-class");
                }, 2000);
                return;
            }

            await postWithCredentials(SERVER_URL + "createSubscription", {
                userId: userId,
                userEmail: userEmail,
                classId: id,
            })
                .then((res) => {
                    // console.log('subscription created');
                    setClientSecret(res.data.clientSecret);
                })
                .catch((err) => {
                    // console.log(err);
                });
        };
        subscribe();
    }, [location.search, navigate]);

    const options = {
        clientSecret,
        appearance: {
            // Check Stripe Appearance for more customizations
            theme: "stripe",
            rules: {
                ".Tab": {
                    border: "1px solid #E0E6EB",
                    boxShadow:
                        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(255, 255, 255, 0.02)",
                },

                ".Tab:hover": {
                    color: "var(--colorText)",
                },

                ".Tab--selected": {
                    borderColor: "#E0E6EB",
                    boxShadow:
                        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
                },

                ".Input--invalid": {
                    boxShadow:
                        "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
                },
            },
        },
    };

    return (
        <Card
            body
            className="pb-0"
            style={{
                border: "none",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
        >
            <BannerImage url="/img/CYN_Cynorix.jpg"/>
            <h4 className="mt-3">
                <b>Checkout</b>
            </h4>
            <hr className="mt-2"/>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutPage clientSecret={clientSecret}/>
                </Elements>
            )}
            <FeedbackAlert feedback={feedback} className="mb-0"/>
        </Card>
    );
}
