import React from "react";
import {Card} from "react-bootstrap";
import BannerImage from "./BannerImage";

export default function NotFound() {
    return (
        <Card
            body
            style={{
                border: "none",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
        >
            <BannerImage url="/img/CYN_Cynorix.jpg"/>
            <h4 className="mt-3">
                <b>404 - Page Not Found</b>
            </h4>
            <hr className="mt-0"/>
            Sorry, the page you're looking for doesn't exist.
        </Card>
    );
}
