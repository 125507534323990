import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as data from "../assets/json/notifications.json";
import TextModal from "./TextModal";
import {Card, CloseButton, ListGroup} from "react-bootstrap";
import {
    CONNECTION_ERROR,
    DECRYPTION_BAD_PROOF,
    DECRYPTION_NEEDS_VERIFICATION,
    DECRYPTION_NO_USES,
    DECRYPTION_NO_VERIFICATION,
    DECRYPTION_NOT_FOUND,
    DECRYPTION_PHONE_NOT_RECOGNIZED,
    DECRYPTION_SUCCESS,
    TWO_FACTOR_APPROVED,
    TWO_FACTOR_ATTEMPT_FAIL,
    TWO_FACTOR_CANCEL_SUCCESS,
    TWO_FACTOR_CANCELED,
    TWO_FACTOR_TRIES_EXCEEDED,
} from "../assets/js/constants";

import {decrypt1, getFileIdentifier1, oldDecrypt1} from "../services/decryptionUtilsNew";
import * as server from "../services/server-connector";
import "./DecryptionPage.css";

import AuthDialog from "./AuthDialog2";
import ComponentCard from "./ComponentCard";
import FeedbackAlert from "./FeedbackAlert";
import FeedbackProgressBar from "./FeedbackProgressBar";

const notifications = data;
let canNewEncrypt = "showSaveFilePicker" in window;

/**
 * Handles all frontend logic relevant to the decryption flow
 */
class DecryptionPage extends Component {
    /**
     * Initializes state and handlers for buttons
     * @param props - React props
     */
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            number: "",
            show2FADialog: false,
            verificationSID: "",
            email: "",
            //decryptorEmail: "",
            verificationType: "",
            feedbackVariant: "",
            feedbackMessage: "",
            showFeedback: false,
            downloading: false,
            downloadProgress: 0,
            submit: false,
            modalHeader: "",
            modalMessage: "",
            showModal: false,
        };
        this.setState = this.setState.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleDropUpload = this.handleDropUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRepeatSubmit = this.handleRepeatSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.evaluateDecryption = this.evaluateDecryption.bind(this);
        this.cancel2FA = this.cancel2FA.bind(this);
        this.checkCode = this.checkCode.bind(this);

        /**
         * Cancels 2FA request upon auth dialog "cancel" button click
         */
        this.cancel2FACloseDialog = () => {
            this.cancel2FA().then((res) => {
                this.setState({show2FADialog: false});
            });
        };
        this.cancel2FACloseDialog = this.cancel2FACloseDialog.bind(this);

        this.setModal = (object) => {
            this.setState({modalHeader: object.title});
            this.setState({modalMessage: object.message});
            this.setState({showModal: true});
        };
        this.setModal = this.setModal.bind(this);

        this.setModalParam = (message) => {
            this.setState({modalHeader: notifications.wrongParam.title});
            this.setState({modalMessage: message});
            this.setState({showModal: true});
        };
        this.setModalParam = this.setModalParam.bind(this);

        /**
         * Resends the 2FA request when the user asks by re-initiating decrypt and canceling previous 2FA request
         */
        this.resend2FA = () => {
            this.cancel2FA().then((res) => this.decryptFile(true));
        };
        this.resend2FA = this.resend2FA.bind(this);
    }

    /**
     * Gets the file identifier and ports it to the server. Begins decryption or sends 2FA request.
     * @param {Boolean} retry - Whether the current request is new or is a resend. Default is false
     */
    async decryptFile(retry = false) {
        let page = this;
        getFileIdentifier1(this.state.file)
            .then((id) => {
                page.setState({fileInit: id});
                if (
                    page.state.verificationType === "phone" ||
                    page.state.verificationType === "none"
                ) {
                    return server.getDecryptionData(id, page.state.number);
                } else {
                    return server.getDecryptionData(id, page.state.email);
                }
            })
            .then((res) => {
                if (res.message === DECRYPTION_NEEDS_VERIFICATION) {
                    retry
                        ? this.setModal(notifications.twoFactor.resent)
                        : this.setModal(notifications.twoFactor.isRequired);
                    page.setState({show2FADialog: true, verificationSID: res.sid});
                } else if (res.message === DECRYPTION_PHONE_NOT_RECOGNIZED) {
                    this.setModal(notifications.twoFactor.badPhone);
                } else if (res.message === DECRYPTION_NO_VERIFICATION) {
                    if (canNewEncrypt) {
                        window
                            .showSaveFilePicker({
                                suggestedName:
                                    this.state.file.name.slice(
                                        0,
                                        this.state.file.name.length - 4
                                    ) + ".zip",
                                types: [
                                    {
                                        description: "Zip file",
                                        accept: {
                                            "application/zip": [".zip"],
                                        },
                                    },
                                ],
                            })
                            .then((handle) => {
                                this.setState({showFeedback: true, feedbackVariant: "info", feedbackMessage: "Decrypting..."});
                                this.setState({downloading: true, downloadProgress: 0});
                                decrypt1(page.state.file, res.key, res.iv, handle, this.setState)
                                    .then(page.evaluateDecryption)
                                    .then(() => {
                                        this.setState({downloading: false, downloadProgress: 0});
                                    })
                                });
                    } else {
                        this.setState({showFeedback: true, feedbackVariant: "info", feedbackMessage: "Decrypting..."});
                        this.setState({downloading: true, downloadProgress: 0});
                        oldDecrypt1(page.state.file, res.key, res.iv).then(
                            page.evaluateDecryption
                        );
                        this.setState({downloading: false, downloadProgress: 0});
                    }
                } else if (res.message === DECRYPTION_NOT_FOUND) {
                    this.setModal(notifications.decryption.notFound);
                } else {
                    this.setModal(notifications.decryption.failure);
                }
            })
            .catch((err) => this.setModal(notifications.decryption.failure));
    }

    /**
     * Sends a confirmation to the server indicating file has been decrypted
     * @param {Object} data - Contains proof and file identifier from decryption request
     */
    evaluateDecryption(data) {
        if (this.state.verificationType === "email") {
            server
                .sendDecryptSuccess(data.init, data.proof, this.state.email)
                .then((result) => {
                    switch (result.message) {
                        case DECRYPTION_SUCCESS:
                            this.setState({feedbackVariant: "success", feedbackMessage: notifications.decryption.success.message});
                            this.setModal(notifications.decryption.success);
                            break;
                        case DECRYPTION_NOT_FOUND:
                            this.setState({feedbackVariant: "danger", feedbackMessage: notifications.decryption.notFound.message});
                            this.setModal(notifications.decryption.notFound);
                            break;
                        case DECRYPTION_NO_USES:
                            this.setState({feedbackVariant: "warning", feedbackMessage: notifications.decryption.noUses.message});
                            this.setModal(notifications.decryption.noUses);
                            break;
                        case DECRYPTION_BAD_PROOF:
                            this.setState({feedbackVariant: "danger", feedbackMessage: notifications.decryption.failure.message});
                            this.setModal(notifications.decryption.failure);
                            break;
                        default:
                            return;
                    }
                })
                .catch((err) =>
                    err !== CONNECTION_ERROR
                        ? this.setModal(notifications.decryption.failure)
                        : null
                );
        } else {
            server
                .sendDecryptSuccess(data.init, data.proof, this.state.number)
                .then((result) => {
                    switch (result.message) {
                        case DECRYPTION_SUCCESS:
                            this.setState({feedbackVariant: "success", feedbackMessage: notifications.decryption.success.message});
                            this.setModal(notifications.decryption.success);
                            break;
                        case DECRYPTION_NOT_FOUND:
                            this.setState({feedbackVariant: "danger", feedbackMessage: notifications.decryption.notFound.message});
                            this.setModal(notifications.decryption.notFound);
                            break;
                        case DECRYPTION_NO_USES:
                            this.setState({feedbackVariant: "warning", feedbackMessage: notifications.decryption.noUses.message});
                            this.setModal(notifications.decryption.noUses);
                            break;
                        case DECRYPTION_BAD_PROOF:
                            this.setState({feedbackVariant: "danger", feedbackMessage: notifications.decryption.failure.message});
                            this.setModal(notifications.decryption.failure);
                            break;
                        default:
                            return;
                    }
                })
                .catch((err) =>
                    err !== CONNECTION_ERROR
                        ? this.setModal(notifications.decryption.failure)
                        : null
                );
        }
    }

    /**t
     * Sends a request to the server, initiatied by the user, to cancel the current 2FA request
     * @returns {Promise} - Returns a promise with the server's response
     */
    cancel2FA() {
        return server
            .cancel2FARequest(this.state.verificationSID, this.state.verificationType)
            .then((res) => {
                if (res.message === TWO_FACTOR_CANCEL_SUCCESS) {
                    this.setModal(notifications.twoFactor.cancelSuccess);
                }
            });
    }

    /**
     * Checks if the current code is correct by calling backend. Emits a notification in response.
     * @param {string} code - Code for 2FA provided by user
     */
    async checkCode(code) {
        if (!code) {
            this.setModal(notifications.twoFactor.empty);
            return false;
        }

        let re = /^\d+/;
        if (!re.exec(code)) {
            this.setModal(notifications.twoFactor.wrongFormat);
            return false;
        }

        
        let promise = new Promise((res, rej) => res());
        let page = this;

        if (!this.state.fileInit) {
            promise = promise
                .then((res) => getFileIdentifier1(page.state.file))
                .then((id) => {
                    page.setState({fileInit: id});
                    return id;
                });
        } else {
            promise = promise.then((res) => {
                return page.state.fileInit;
            });
        }

        if (page.state.verificationType === "phone") {
            promise
                .then((id) =>
                    server.checkCode(
                        code,
                        page.state.number,
                        page.state.verificationType,
                        id
                    )
                )
                .then((res) => {
                    switch (res.message) {
                        case TWO_FACTOR_APPROVED:
                            this.setModal(notifications.twoFactor.approved);
                            page.setState({show2FADialog: false});
                            this.setState({downloading: true, downloadProgress: 0});
                            if (canNewEncrypt) {
                                window
                                    .showSaveFilePicker({
                                        suggestedName:
                                            this.state.file.name.slice(
                                                0,
                                                this.state.file.name.length - 4
                                            ) + ".zip",
                                        types: [
                                            {
                                                description: "Zip file",
                                                accept: {
                                                    "application/zip": [".zip"],
                                                },
                                            },
                                        ],
                                    })
                                    .then((handle) =>{
                                        this.setState({showFeedback: true, feedbackVariant: "info", feedbackMessage: "Decrypting..."});
                                        this.setState({downloading: true, downloadProgress: 0});
                                        decrypt1(
                                            page.state.file,
                                            res.key,
                                            res.iv,
                                            handle,
                                            this.setState
                                        )
                                            .then(page.evaluateDecryption)
                                            .then(() => {
                                                this.setState({
                                                    downloading: false,
                                                    downloadProgress: 0,
                                                });
                                            })
                                        });
                            } else {
                                this.setState({showFeedback: true, feedbackVariant: "info", feedbackMessage: "Decrypting..."});
                                this.setState({downloading: true, downloadProgress: 0});
                                oldDecrypt1(page.state.file, res.key, res.iv).then(
                                    page.evaluateDecryption
                                );
                                this.setState({downloading: false, downloadProgress: 0});
                            }
                            break;
                        case TWO_FACTOR_ATTEMPT_FAIL:
                            this.setModal(notifications.twoFactor.attemptFail);
                            break;
                        case TWO_FACTOR_TRIES_EXCEEDED:
                            this.setModal(notifications.twoFactor.triesExceeded);
                            page.setState({show2FADialog: false});
                            break;
                        case TWO_FACTOR_CANCELED:
                            this.setModal(notifications.twoFactor.canceled);
                            page.setState({show2FADialog: false});
                            break;
                        case DECRYPTION_NOT_FOUND:
                            this.setModal(notifications.decryption.notFound);
                            page.setState({show2FADialog: false});
                            break;
                        default:
                            break;
                    }
                });
        }
        if (page.state.verificationType === "email") {
            promise
                .then((id) =>
                    server.checkCode(
                        code,
                        page.state.email,
                        page.state.verificationType,
                        id
                    )
                )
                .then((res) => {
                    switch (res.message) {
                        case TWO_FACTOR_APPROVED:
                            this.setModal(notifications.twoFactor.approved);
                            page.setState({show2FADialog: false});
                            this.setState({downloading: true, downloadProgress: 0});
                            if (canNewEncrypt) {
                                window
                                    .showSaveFilePicker({
                                        suggestedName:
                                            this.state.file.name.slice(
                                                0,
                                                this.state.file.name.length - 4
                                            ) + ".zip",
                                        types: [
                                            {
                                                description: "Zip file",
                                                accept: {
                                                    "application/zip": [".zip"],
                                                },
                                            },
                                        ],
                                    })
                                    .then((handle) => {
                                        this.setState({showFeedback: true, feedbackVariant: "info", feedbackMessage: "Decrypting..."});
                                        this.setState({downloading: true, downloadProgress: 0});
                                        decrypt1(
                                            page.state.file,
                                            res.key,
                                            res.iv,
                                            handle,
                                            this.setState
                                        )
                                            .then(page.evaluateDecryption)
                                            .then(() => {
                                                this.setState({
                                                    downloading: false,
                                                    downloadProgress: 0,
                                                });
                                            })
                                        });
                            } else {
                                this.setState({showFeedback: true, feedbackVariant: "info", feedbackMessage: "Decrypting..."});
                                this.setState({downloading: true, downloadProgress: 0});
                                oldDecrypt1(page.state.file, res.key, res.iv).then(
                                    page.evaluateDecryption
                                );
                                this.setState({downloading: false, downloadProgress: 0});
                                
                            }
                            break;
                        case TWO_FACTOR_ATTEMPT_FAIL:
                            this.setModal(notifications.twoFactor.attemptFail);
                            break;
                        case TWO_FACTOR_TRIES_EXCEEDED:
                            this.setModal(notifications.twoFactor.triesExceeded);
                            page.setState({show2FADialog: false});
                            break;
                        case TWO_FACTOR_CANCELED:
                            this.setModal(notifications.twoFactor.canceled);
                            page.setState({show2FADialog: false});
                            break;
                        case DECRYPTION_NOT_FOUND:
                            this.setModal(notifications.decryption.notFound);
                            page.setState({show2FADialog: false});
                            break;
                        default:
                            break;
                    }
                });
        }
    }

    /**
     * Changes the state variables in response to a change to the input fields
     * @param {InputEvent} event - HTML DOM event indicating a change in an input field
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    /**
     * Handles file upload
     * @param {Event} event - Event containing reference to the file
     */
    handleUpload(event) {
        if (!event.target.files[0]) {
            return;
        }
        if (event.target.files[0].name.endsWith(".ecy")) {
            this.setState({
                file: event.target.files[0],
                verificationType: "email",
            });
        } else if (event.target.files[0].name.endsWith(".pcy")) {
            this.setState({
                file: event.target.files[0],
                verificationType: "phone",
            });
        } else {
            this.setState({
                file: event.target.files[0],
                verificationType: "none",
            });
        }
    }

    /**
     * Handles file upload
     * @param {Event} event - Event containing reference to the file
     */
    handleDropUpload(event) {
        const files = event.dataTransfer.files;
        if (!files[0]) {
            return;
        }
        if (files[0].name.endsWith(".ecy")) {
            this.setState({
                file: files[0],
                verificationType: "email",
            });
        } else if (files[0].name.endsWith(".pcy")) {
            this.setState({
                file: files[0],
                verificationType: "phone",
            });
        } else {
            this.setState({
                file: files[0],
                verificationType: "none",
            });
        }
    }

    /**
     * Handles submit request on form. Checks to see if phone is valid and ensures all fields are filled.
     */
    handleSubmit() {
        let phoneRegex = /^\d{11,}$/;
        let emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.file === "") {
            this.setModalParam(notifications.wrongParam.file);
            return;
        } else if (
            !this.state.file.name.endsWith(".ncy") &&
            !this.state.file.name.endsWith(".pcy") &&
            !this.state.file.name.endsWith(".ecy")
        ) {
            this.setModalParam(notifications.wrongParam.badFile);
            return;
        } else if (
            this.state.verificationType === "phone" &&
            (this.state.number === "" || !phoneRegex.exec(this.state.number))
        ) {
            this.setModalParam(notifications.wrongParam.phone);
            return;
        } else if (
            this.state.verificationType === "email" &&
            (this.state.email === "" || !emailRegex.exec(this.state.email))
        ) {
            this.setModalParam(notifications.wrongParam.email);
        }
        this.decryptFile();
    }

    handleRepeatSubmit() {
        if (this.state.submit) {

        } else {
            this.setState({submit: true});
            this.handleSubmit();
            setTimeout(() => {
                this.setState({submit: false});
            }, 4000);
        }
    }

    /**
     * Handles email change
     */
    handleChangeEmail(event) {
        this.setState({email: event.target.value.toLowerCase()});
    }

    /**
     * Renders visible components of decryption page
     * @returns - JSX Object
     */
    render() {
        const helpContent = (
            <>
                {" "}
                <p className="mb-1">
                    A person who has received an encrypted zipped folder will go to this
                    page to decrypt their encrypted file. On the page, click{" "}
                    <b>Click to add File</b> to upload the document you want to decrypt.
                    Once uploaded, click <b>Submit</b>.
                </p>
                <p className="mb-1">
                    After submitting, if the sender has chosen a form of two-factor
                    authentication:
                    <ul>
                        <li>
                            <b>Phone Number</b>: The sender entered a phone number associated
                            to you when encrypting this file. You will need to enter that
                            phone number, and provide the SMS verification code sent to that
                            number.
                        </li>
                        <li>
                            <b>Email</b>: The sender entered an email address associated to
                            you when encrypting this file. You will need to enter that
                            address, and provide the email verification code sent to that
                            address.
                        </li>
                    </ul>
                    Afterwards, the file will be decrypted and downloaded to your
                    computer.
                </p>
            </>
        );

        return (
            <>
                <ComponentCard title="Decrypt Files" helpContent={helpContent}>
                    <Row>
                        <Col>
                            <div className="w-150r">
                                {this.state.show2FADialog ? (
                                    <AuthDialog
                                        checkCode={async (code) => {
                                            await this.checkCode(code);
                                        }}
                                        cancel={this.cancel2FACloseDialog}
                                        verificationType={this.state.verificationType}
                                        resend={this.resend2FA}
                                        value={
                                            this.state.verificationType === "phone"
                                                ? this.state.number
                                                : this.state.email
                                        }
                                        change={
                                            this.state.verificationType === "phone"
                                                ? this.setState
                                                : this.handleChangeEmail
                                        }
                                        state={this.state}
                                    />
                                ) : (
                                    <Row className="m-1">
                                        {/* <Col className="m-1" lg={12} md={12} sm={12} xs={12}>
                        <input
                            type='email'
                            name='decryptorEmail'
                            placeholder="Enter your email here"
                            value={this.state.decryptorEmail}
                            onChange={this.handleInputChange}
                            style={{ height: '40px', width: '30%', fontSize: '20px' }} />
                    </Col> */}
                                        <Row>
                                            <Col md={12} sm={12} style={{marginBottom: 10}}>
                                                <Card
                                                    className="Row"
                                                    style={{
                                                        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                                                    }}
                                                >
                                                    <input
                                                        type="file"
                                                        id="userFile"
                                                        name="userFile"
                                                        onChange={this.handleUpload}
                                                        hidden
                                                    />
                                                    <label
                                                        htmlFor="userFile"
                                                        className="btn"
                                                        style={{
                                                            minHeight: 120,
                                                            height: "100%",
                                                            display: "flex",
                                                            borderRadius: 10,
                                                            margin: 10,
                                                            border: "2px dashed gray",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        onDragOver={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            this.handleDropUpload(e);
                                                        }}
                                                    >
                                                        Click to add File
                                                    </label>
                                                    <Card.Body
                                                        style={{
                                                            display: this.state.file.name ? null : "none",
                                                        }}
                                                    >
                            <span
                                className="ms-3"
                                style={{
                                    color: "#424242",
                                }}
                            >
                              <strong>Files Uploaded</strong>
                            </span>
                                                        <ListGroup
                                                            style={{
                                                                maxHeight: "20vh",
                                                                overflow: "scroll",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <ListGroup.Item>
                                                                {this.state.file.name}
                                                                <CloseButton
                                                                    style={{float: "right"}}
                                                                    aria-label="Remove Upload"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            file: "",
                                                                        });
                                                                    }}
                                                                />
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>

                                        {this.state.verificationType === "email" ? (
                                            <div>
                                                <p>The sender of the file requires you to complete 
                                                2-Factor Authentication (2FA) before you decrypt
                                                their file. Please enter the required information 
                                                below and click Proceed.</p>
                                                <Row className="mt-3">
                                                    <Col
                                                        lg={1}
                                                        md={3}
                                                        sm={12}
                                                        xs={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                        className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                    >
                                                        <label style={{marginLeft: "1%"}}>Email</label>
                                                    </Col>
                                                    <Col lg={9} md={7} sm={12} xs={12}>
                                                        <div className="w-100 mx-auto">
                                                            <input
                                                                className="form-control"
                                                                type="email"
                                                                placeholder="example@abc.com"
                                                                value={this.state.email}
                                                                onChange={this.handleChangeEmail}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        lg={2}
                                                        md={2}
                                                        sm={2}
                                                        xs={2}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                        className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                    >
                                                        <Button
                                                            style={{
                                                                backgroundColor: "#800000",
                                                                color: "white",
                                                                border: "black",
                                                                width: "100%",
                                                            }}
                                                            onClick={async () => {
                                                                await this.handleRepeatSubmit();
                                                            }}
                                                        >
                                                            Proceed
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col
                                                className="m-1 mt-3 w-100"
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                >
                                                    {this.state.showFeedback === true ? (
                                                        <FeedbackAlert feedback={{loading: this.state.downloading, variant: this.state.feedbackVariant, message: this.state.feedbackMessage}} />
                                                    ) : null}
                                                    <FeedbackProgressBar downloadProgress={{loading: this.state.downloading, now: this.state.downloadProgress}}/>
                                                </Col>
                                                </Row>
                                            </div>
                                        ) : this.state.verificationType === "phone" ? (
                                            <div>
                                                <p>The sender of the file requires you to complete 
                                                2-Factor Authentication (2FA) before you decrypt
                                                their file. Please enter the required information 
                                                below and click Proceed.</p>
                                                <Row className="mt-3 pe-3">
                                                    <Col
                                                        lg={2}
                                                        md={3}
                                                        sm={12}
                                                        xs={12}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                        className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                    >
                                                        <label style={{marginLeft: "1%"}}>
                                                            Phone Number
                                                        </label>
                                                    </Col>
                                                    <Col lg={8} md={7} sm={10} xs={10}>
                                                        <div className="w-100 mx-auto my-1 align-center">
                                                            <PhoneInput
                                                                className="w-100 phone"
                                                                country={"ca"}
                                                                value={this.state.number}
                                                                onChange={(number) =>
                                                                    this.setState({number}, () => this.state)
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        lg={2}
                                                        md={2}
                                                        sm={2}
                                                        xs={2}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: 0,
                                                        }}
                                                        className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                    >
                                                        <Button
                                                            style={{
                                                                backgroundColor: "#800000",
                                                                color: "white",
                                                                border: "black",
                                                                width: "100%",
                                                            }}
                                                            onClick={async () => {
                                                                await this.handleRepeatSubmit();
                                                            }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col
                                                className="m-1 mt-3 w-100"
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                >
                                                    {this.state.showFeedback === true ? (
                                                        <FeedbackAlert feedback={{loading: this.state.downloading, variant: this.state.feedbackVariant, message: this.state.feedbackMessage}} />
                                                    ) : null}
                                                    <FeedbackProgressBar downloadProgress={{loading: this.state.downloading, now: this.state.downloadProgress}}/>
                                                </Col>
                                                </Row>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        {this.state.verificationType === "none" ||
                                        this.state.verificationType === "" ? (
                                            <Col
                                                className="m-1 mt-3 w-100"
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Button
                                                    style={{
                                                        backgroundColor: "#800000",
                                                        color: "white",
                                                        border: "black",
                                                        width: "100%",
                                                    }}
                                                    onClick={async () => {
                                                        await this.handleRepeatSubmit();
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                                {this.state.showFeedback === true ? (
                                                        <FeedbackAlert feedback={{loading: this.state.downloading, variant: this.state.feedbackVariant, message: this.state.feedbackMessage}} />
                                                    ) : null}
                                                <FeedbackProgressBar downloadProgress={{loading: this.state.downloading, now: this.state.downloadProgress}}/>
                                            </Col>
                                        ) : null}
                                    </Row>
                                )}
                            </div>
                        </Col>
                    </Row>
                </ComponentCard>
                <TextModal
                    heading={this.state.modalHeader}
                    message={this.state.modalMessage}
                    show={this.state.showModal}
                    handleClose={() => this.setState({showModal: false})}
                />
            </>
        );
    }
}

export default DecryptionPage;
