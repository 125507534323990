import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, ListGroup, Modal,} from "react-bootstrap";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {verifyCode} from "../functions/verify";
import {getUserFromEmail} from "../functions/getUser";
import {EmailAuthProvider, reauthenticateWithCredential} from "firebase/auth";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import ComponentCard from "./ComponentCard";
import PasswordInput from "./PasswordInput";

const timers = require("timers-promises");
library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const HAS_2FA_1 = 1;
const HAS_2FA_2 = 2;
const NO_2FA = 3;

export default function DeleteAccount() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const codeRef = useRef();
    const {currentUser, login, logout} = useAuth();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const user_id = window.location.pathname.slice(
            window.location.pathname.lastIndexOf("/") + 1
        );

        // console.log(second_index);

        // check has 2FA
        postWithCredentials(SERVER_URL + "checkSecret", {
            user_id,
        }).then((res) => {
            // console.log("this is secret ", res.data.secret);

            if (res.data.secret === true) {
                setPage(HAS_2FA_1);
                // console.log("Has 2fa");
            } else {
                setPage(NO_2FA);
                // console.log("no 2fa");
            }
        });
    }, []);

    const setModal = (object) => {
        setModalHeader(object.title);
        setModalMessage(object.message);
        setShowModal(true);
    };

    const handleInitialSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);
        // if you're logged in:
        const emailLowercase = emailRef.current.value.toLowerCase();
        if (currentUser !== null) {
            // console.log(currentUser);
            const userEmail = currentUser.email;
            if (userEmail !== emailLowercase) {
                setError("Provided email is incorrect.");
                setLoading(false);
                return;
            }
            // reauthenticate
            const credential = EmailAuthProvider.credential(
                emailLowercase,
                passwordRef.current.value
            );
            try {
                await reauthenticateWithCredential(
                    currentUser,
                    credential
                );
            } catch (e) {
                setLoading(false);
                console.log(e);
                setError("Please check your credentials and Try again!");
                return;
            }
        } else {
            // user is NOT logged in
            const errCode = await login(emailLowercase, passwordRef.current.value);
            if (errCode) {
                const errMsg = handleLoginError(errCode);
                setModal({
                    title: "Error",
                    message: errMsg,
                });
                return;
            }
        }

        if (page === HAS_2FA_1) {
            setPage(HAS_2FA_2);
        } else {
            setModal({
                title: "Confirm Delete Account",
                message:
                    "Your account has been marked for deletion. You may reactivate your account through the login page.",
            });
        }
    };

    const check2FA = async (e) => {
        e.preventDefault();

        //check 2FA
        const userInfo = await getUserFromEmail(currentUser.email.toLowerCase());
        const verified = await verifyCode(codeRef.current.value, userInfo.user_id);
        if (!verified) {
            setError("Code Invalid.");
            setLoading(false);
            return;
        }

        setModal({
            title: "Confirm Delete Account",
            message:
                "Your account has been marked for deletion. You may reactivate your account through the login page.",
        });
        setShowModal(true);
    };

    const handleLoginError = (errorCode) => {
        switch (errorCode) {
            case "auth/invalid-email":
                return "This email is invalid.";
            case "auth/user-disabled":
                return "This user has been disabled.";
            case "auth/user-not-found":
                return "User not found.";
            case "auth/wrong-password":
            case "auth/internal-error":
                return "Wrong password.";
            case "requires_activation":
                return "Your account is marked as deleted already.";
            default:
                console.log(errorCode, "here");
                return "An unknown error has occurred.";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const emailLowercase = currentUser.email.toLowerCase();
            const userInfo = await getUserFromEmail(emailLowercase);
            await deleteAllUserData(userInfo);
            setShowModal(false);
        } catch (err) {
            console.log(err);
            setError("Unable to delete your account. Please try again.");
            setShowModal(false);
        }
        setLoading(false);
        await timers.setTimeout(10000);
        navigate("/");
    };

    const deleteAllUserData = async ({user_id, subscription_id}) => {
        // cancel subscription first
        await postWithCredentials(`${SERVER_URL}updateSubscription`, {
            subId: subscription_id,
            userId: user_id,
            cancellingSubs: true,
        })
            .then(() => {
                console.log("cancelled subscription");
            })
            .catch(() => {
                console.log("error while cancelling subscription");
            });

        // delete account second
        await postWithCredentials(SERVER_URL + "deleteAllUserData", {
            userId: user_id,
            email: currentUser.email,
        })
            .then(async (res) => {
                const delete_date = new Date(res.data.deletion_date);
                setSuccess(
                    `Your account has been marked for deletion.
          If you did this by accident, or want to recover your account, you can 
          log back in before the deletion date: ${delete_date.toDateString()}.`
                );
                logout();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    const helpContent = (
        <>
            <p className="mb-1">
                To delete your account, you need to provide your email address, your
                password, as well as a 2FA code from your phone if you set 2FA up. Once
                you click <b>Delete</b>, your account will be marked for deletion.
            </p>
            <p className="mb-1">
                If you change your mind before the date of deletion, you can re-activate
                your account by logging back in. The login page will ask to confirm that
                you want to reactivate your account, and then your account will be
                reactivated. While your account is deactivated, you cannot create a new
                account with the same email.
            </p>
            <p className="mb-0">
                <b>Note</b>: Deleting your account will cancel your subscription at the
                end of the billing period.
            </p>
        </>
    );

    return (
        <>
            <ComponentCard title="Delete Account" helpContent={helpContent}>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                {(page === HAS_2FA_1 || page === NO_2FA) && (
                    <Form onSubmit={handleInitialSubmit} className="w-100 text-start">
                        <Form.Group id="email" className="mb-3 row">
                            <Col xs={12} sm={"auto"}>
                                <Form.Label column style={{width: "8ch"}}>
                                    Email
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="email"
                                    id="email"
                                    placeholder="example@mail.com"
                                    ref={emailRef}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group id="password" className="mb-3 row">
                            <Col xs={12} sm={"auto"}>
                                <Form.Label column style={{width: "8ch"}}>
                                    Password
                                </Form.Label>
                            </Col>
                            <Col>
                                <PasswordInput
                                    className="form-control"
                                    id="password"
                                    ref={passwordRef}
                                    placeholder="**********"
                                />
                            </Col>
                        </Form.Group>
                        <Button
                            type="submit"
                            disabled={loading || success}
                            className="w-100"
                        >
                            Confirm
                        </Button>
                    </Form>
                )}
                {page === HAS_2FA_2 && (
                    <>
                        <span>Steps:</span>
                        <ListGroup as="ol" numbered className="pb-3">
                            <ListGroup.Item as="li">
                                Open Google Authenticator App on your mobile phone.
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                Enter the six-digit code in the input box and click the button.
                            </ListGroup.Item>
                        </ListGroup>
                        <Form onSubmit={check2FA} className="w-100 text-start">
                            <Form.Group id="2fa_code" className="row mt-1">
                                <Col xs={12} sm={"auto"}>
                                    <Form.Label column style={{width: "8ch"}}>
                                        2FA Code
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control id="google-code" type="text" ref={codeRef}/>
                                </Col>
                            </Form.Group>
                            <Button type="submit" className="mt-3 w-100">
                                Delete
                            </Button>
                        </Form>
                    </>
                )}
            </ComponentCard>

            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>{modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{
                            backgroundColor: "#800000",
                            color: "white",
                            border: "black",
                        }}
                        onClick={() => {
                            setShowModal(false);
                            navigate("/dashboard");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#800000",
                            color: "white",
                            border: "black",
                        }}
                        onClick={handleSubmit}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
