import React from "react";

const PasswordRequirements = ({validity: {minChar, number, upper, lower, specialChar}}) => {
    return (
        <div className=" mb-4">
            <ul className="text-muted">
                <PasswordRequiredItem
                    isValid={minChar}
                    text="At least 10 characters"
                />
                <PasswordRequiredItem
                    isValid={number}
                    text="At least 1 number"
                />
                <PasswordRequiredItem
                    isValid={upper}
                    text="At least 1 Uppercase letter"
                />
                <PasswordRequiredItem
                    isValid={lower}
                    text="At least 1 lowercase letter"
                />
                <PasswordRequiredItem
                    isValid={specialChar}
                    text="At least 1 special character"
                />
            </ul>
        </div>
    );
};

const PasswordRequiredItem = ({isValid, text}) => {
    const highlightClass = isValid
        ? "text-success"
        : isValid !== null
            ? "text-danger"
            : "";
    return <li className={highlightClass}>{text}</li>;
};

export default PasswordRequirements;