import React, { useState } from "react";
import FeedbackAlert from "./FeedbackAlert";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import BannerImage from "./BannerImage";
import { postWithCredentials } from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Contact() {
    const [feedback, setFeedback] = useState(null);

    async function handleSubmit(e) {
        e.preventDefault();

        setFeedback({
            loading: true,
            message: "Sending Message...",
            variant: "info",
        });

        try {
            await postWithCredentials(SERVER_URL + "SendFAQ", {
                name: e.target.name.value,
                email: e.target.email.value.toLowerCase(),
                message: e.target.message.value,
                copy: e.target.wantCopy.checked,
            });

            setFeedback({
                message: "Message Sent!",
                variant: "success",
            });
            e.target.reset();
        } catch (e) {
            setFeedback({
                message: "Failed to send your message.",
                variant: "danger",
            });
        }
    }

    return (
        <Card style={{ border: "none", boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)" }}>
            <Card.Body>
                <BannerImage url="/img/CYN_Cynorix.jpg"/>
                <h4 className="mt-3">
                    <b>Contact Us!</b>
                </h4>
                <hr className="mt-0"/>
                <Row>
                    <Col md={12} sm={12} style={{ marginBottom: 10 }}>
                        <div className='w-100 d-flex justify-content-center'>
                            <Form className='w-100' onSubmit={handleSubmit}>
                                <Form.Group controlId="senderName" className="mt-3">
                                    <Row className="mb-2">
                                        <Col xs={12} lg={1} style={{ display: "flex", alignItems: "center", padding: 0, }} className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                            <label className="form-label" style={{marginBottom: "0"}} for="senderName">Name</label>
                                        </Col>
                                        <Col>
                                            <Form.Control name="name" placeholder='John Doe' required/>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="emailAddress" className="mt-3">
                                    <Row className="mb-2">
                                        <Col xs={12} lg={1} style={{ display: "flex", alignItems: "center", padding: 0, }} className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                            <label className="form-label" style={{marginBottom: "0"}} for="emailAddress">Email</label>
                                        </Col>
                                        <Col>
                                            <Form.Control type="email" name="email" placeholder='example@email.com' required/>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="messageBody" className="mt-3">
                                    <Row className="mb-2">
                                        <Col xs={12} lg={1} style={{ display: "flex", alignItems: "center", padding: 0 }} className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                            <label class="form-label" style={{marginBottom: "0"}} for="messageBody">Message</label>
                                        </Col>
                                        <Col>
                                            <Form.Control as="textarea" name="message" placeholder='Please type your message here..' rows={4} required/>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="copyMeToggle" className="mt-3">
                                    <Col className="d-flex justify-content-center mb-4">
                                        <Form.Check
                                            className="form-check-label"
                                            type="checkbox"
                                            name="wantCopy"
                                            label="Send me a copy of this message"
                                        />
                                    </Col>
                                </Form.Group>
                                <Button className="btn btn-primary w-100" type="submit"> Send </Button>
                                <FeedbackAlert feedback={feedback} className="mt-3 mb-0"/>
                            </Form>        
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
