import React, {createRef, useRef, useState} from "react";
import {Alert, Col, Collapse, Form, Row} from "react-bootstrap";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PasswordRequirements from "../functions/PasswordReq";
import "./LoginMD.css";
import LoadingButton from "./LoadingButton";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import "./Signup.css";
import ComponentCard from "./ComponentCard";
import PasswordInput from "./PasswordInput";
import CryptoJS from "crypto-js";

library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SECRET = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;

export default function Signup() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const passwordConfirmRef = useRef();
    const reRef = createRef();
    const verificationCodeRef = useRef();
    const {signup} = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [verifying, setVerifying] = useState(false);
    const [handling, setHandling] = useState(false);
    const [timeLeft, setTimeLeft] = useState(120);
    const [codeSent, setCodeSent] = useState(false);
    const [codeError, setCodeError] = useState("");
    const [passwordFocused, setPasswordFocused] = useState(false);


    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        upper: null,
        lower: null,
        specialChar: null,
    });
    let [remainingSubmit, setRemainingSubmit] = useState(3);
    let [code, setCode] = useState("");

    const navigate = useNavigate();
    const isNumberRegx = /\d/;
    const isUpperRegx = /[A-Z]/;
    const isLowerRegx = /[a-z]/;
    const specialCharacterRegx = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    const onChangePassword = () => {
        const password = passwordRef.current.value;
        // console.log(password, passwordFocused, "heheheheh");
        setPasswordValidity({
            minChar: password.length >= 10 ? true : false,
            number: isNumberRegx.test(password) ? true : false,
            upper: isUpperRegx.test(password) ? true : false,
            lower: isLowerRegx.test(password) ? true : false,
            specialChar: specialCharacterRegx.test(password) ? true : false,
        });
    };

    const handleSendVerificationCode = async (e) => {
        e.preventDefault();
        try {
            //avoid the server to handle two request at the same time
            if (handling) {
                // console.log("handling");
                return;
            } else {
                setHandling(true);
            }

            const isValidPassword = Object.values(passwordValidity).every((field) => field === true);

            // Check if the email is valid
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(emailRef.current.value);
            if (!isValidEmail) {
                setHandling(false);
                throw new Error("Please enter a valid email address.");
            }

            // Check if the first name is filled
            const firstNameValue = firstNameRef.current.value;
            if (!firstNameValue.trim()) {
                setHandling(false);
                throw new Error("Please enter your first name.");
            }

            // Check if the last name is filled
            const lastNameValue = lastNameRef.current.value;
            if (!lastNameValue.trim()) {
                setHandling(false);
                throw new Error("Please enter your last name.");
            }

            //Check password
            if (!isValidPassword) {
                setHandling(false);
                throw new Error("The password you entered does not meet our security requirements. Please choose a different password and try again.");
            }
            if (passwordRef.current.value !== passwordConfirmRef.current.value) {
                setHandling(false);
                throw new Error("Passwords do not match");
            }

            //Verify reCATPCHA
            const token = await reRef.current.getValue();
            // console.log("verifying capch");

            await verifyCaptcha(token);

            //Set remainingSubmit equal to 3
            setRemainingSubmit(3);

            // generate 6-digit code
            let randomCode = (Math.floor(Math.random() * 1000000) + 1000000)
                .toString()
                .substring(1);
            setCode(randomCode);


            await sendVerificationCodeEmail(randomCode);

            // start countdown after email is sent
            setCodeSent(true);
            setVerifying(true);
            setLoading(false);
            setTimeout(() => {
                setVerifying(false);
                setTimeLeft(30);
                setLoading(true);
                setCode("");
                setRemainingSubmit(3);
            }, 120000);
            // show countdown after email is sent
            let counter = timeLeft - 1;
            const interval = setInterval(() => {
                setTimeLeft(counter);
                counter--;
                if (counter < 0) {
                    clearInterval(interval);
                    setTimeLeft(120);
                }
            }, 1000);
        } catch (e) {
            console.log(e);
            setError(e.message);
            setTimeout(() => {
                setError("");
            }, 2000);
        }
        setHandling(false);
    };

    const handleSubmit = async (e) => {
        // console.log("in signup");
        e.preventDefault();
        if (handling) {
            return;
        } else {
            setHandling(true);
        }

        try {
            setError("");
            //Check Code
            let inputCode = verificationCodeRef.current.value;

            if (remainingSubmit > 0) setRemainingSubmit(remainingSubmit - 1);
            if (remainingSubmit === 0) {
                setLoading(true);
                setHandling(false);
                return;
            }
            if (inputCode === code) {
            } else {
                setCodeError(
                    "Your Code is incorrect. You have " +
                    (remainingSubmit - 1) +
                    " more attempts to verify"
                );
                setTimeout(() => {
                    setCodeError("");
                }, 3000);
                setHandling(false);
                return;
            }

            // handle errors
            const errorCode = await signup(
                emailRef.current.value,
                passwordRef.current.value,
                firstNameRef.current.value,
                lastNameRef.current.value
            );
            // console.log("hi im done signing up");
            if (errorCode) {
                if (errorCode === "auth/invalid-email") {
                    setLoading(false);
                    setHandling(false);
                    throw new Error("The email is invalid.");
                }
                if (errorCode === "auth/email-already-in-use") {
                    setLoading(false);
                    setHandling(false);
                    throw new Error("The email is already in use.");
                }
                if (errorCode === "auth/weak-password") {
                    setLoading(false);
                    setHandling(false);
                    throw new Error("The password is too weak.");
                }
                console.log(errorCode);
                throw Error(errorCode);
            }
            // Create a customer on Stripe
            await createCustomer();
            // no error
            setLoading(false);
            setHandling(false);
            navigate("/set-google-auth");
        } catch (e) {
            setError(e.message);
            setTimeout(() => {
                setError("");
                setVerifying(false);
                setLoading(true);
                setCode("");
            }, 2000);
        }
        setLoading(false);
        setHandling(false);
    };

    const createCustomer = async () => {
        await postWithCredentials(SERVER_URL + "createStripeCustomer", {
            name: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
            email: emailRef.current.value,
        })
            .then((response) => {
            })
            .catch((err) => {
                // console.log(err);
                throw new Error("Failed Creating a Customer on Stripe");
            });
    };

    const verifyCaptcha = async (token) => {
        return await postWithCredentials(SERVER_URL + "verifyCaptcha", {
            captcha: token,
        })
            .then((response) => {
                // console.log('Captcha Passed');
            })
            .catch((err) => {
                if (err.response.data.msg) {
                    throw new Error(err.response.data.msg);
                } else {
                    throw new Error("Failed captcha verification");
                }
            });
    };

    const sendVerificationCodeEmail = async (code) => {
        await postWithCredentials(SERVER_URL + "sendVerificationCodeEmail", {
            receiver: emailRef.current.value,
            code: CryptoJS.AES.encrypt(String(code), SECRET).toString(),
        })
            .then((response) => {
            })
            .catch((err) => {
                // console.log(err);
                throw new Error("Failed Sending Verification Email");
            });
    };


    function getVerificationMessage() {
        if (verifying) {
            if (remainingSubmit > 0) {
                return (
                    <Alert className="mb-3" variant="secondary">
                        A 6-digit verification code was sent to the email{" "}
                        {emailRef.current.value}, the code will expire in {timeLeft}{" "}
                        seconds. You can also resend the code after it expires.
                        <br/>
                        {/* <div className="d-flex align-items-center">
              Didn't get code?
              <Button
                onClick={handleSendVerificationCode}
                variant="link"
                as="a"
                className="p-0 mx-2"
                style={{ padding: 0 }}
              >
                Resend
              </Button>
            </div> */}
                    </Alert>
                );
            } else {
                return (
                    <Alert variant="warning" className="mt-3">
                        You failed to enter email verification code 3 times, please retry in{" "}
                        {timeLeft} seconds.
                    </Alert>
                );
            }
        } else if (codeSent) {
            return (
                <Alert variant="warning" className="mt-3">
                    Code is expired, please try another time.
                </Alert>
            );
        } else {
            return null;
        }
    }

    const helpContent = (
        <p className="mb-1">
            On the Sign Up page, you will be asked to provide your email address, name
            and password. You will also need to complete a CAPTCHA before submitting
            your information. Once this information is submitted, a confirmation code
            will be sent to your email address. The interface will prompt you to enter
            this confirmation code within a time window, which upon completion, your
            account is created.
        </p>
    );

    return (
        <ComponentCard title="Register" helpContent={helpContent}>
            <Form onSubmit={handleSendVerificationCode}>
                <fieldset disabled={verifying}>
                    <Form.Group controlId="email" className="mb-3 row">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "14ch"}}>
                                Email
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="email"
                                placeholder="example@mail.com"
                                ref={emailRef}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="firstName" className="mb-3 row">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "14ch"}}>
                                First Name
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Jane"
                                ref={firstNameRef}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="lastName" className="mb-3 row">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "14ch"}}>
                                Last Name
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Doe"
                                ref={lastNameRef}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="password" className="mb-3 row">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "14ch"}}>
                                Password
                            </Form.Label>
                        </Col>
                        <Col>
                            <PasswordInput
                                placeholder="********"
                                defaultValue=""
                                ref={passwordRef}
                                onFocus={() => setPasswordFocused(true)}
                                onBlur={() => setPasswordFocused(false)}
                                onChange={() => onChangePassword()}
                                required="required"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="password-confirm" className="mb-3 row">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "14ch"}}>
                                Confirm Password
                            </Form.Label>
                        </Col>
                        <Col>
                            <PasswordInput
                                name="accountPassConfirm"
                                placeholder="********"
                                ref={passwordConfirmRef}
                                required="required"
                            />
                        </Col>
                        {/* <i>
              <FontAwesomeIcon
                icon={passwordShown ? "eye-slash" : "eye"}
                onClick={togglePassword}
              />
            </i> */}
                    </Form.Group>
                </fieldset>
                <Row>
                    <Col xs={12} sm={"auto"}>
                        <div style={{width: "14ch"}}/>
                    </Col>
                    <Col>
                        <Row>
                            <Col xs={12} sm={"auto"}>
                                <ReCAPTCHA
                                    className="center mb-3"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    ref={reRef}
                                />
                            </Col>
                            <Col xs={"auto"}>
                                {passwordFocused && (
                                    <PasswordRequirements validity={passwordValidity}/>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Collapse in={verifying}>
                <Form className="" onSubmit={handleSubmit}>
                    <hr/>
                    {getVerificationMessage()}
                    <Form.Group id="verification-code" className="mb-3 row">
                        <Form.Label column>Verification Code</Form.Label>
                        <Col xs={12} md={9} lg={8} xl={10}>
                            <Form.Control
                                type="text"
                                ref={verificationCodeRef}
                                disabled={loading}
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Collapse>
            <LoadingButton
                className="w-100"
                id="code-send-button"
                loading={handling || (verifying && loading)}
                disabled={verifying && (loading || remainingSubmit === 0)}
                onClick={(e) => {
                    if (verifying) {
                        handleSubmit(e);
                    } else {
                        handleSendVerificationCode(e);
                    }
                }}
            >
                {verifying ? "Register" : "Next"}
            </LoadingButton>
            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}
            {codeError && (
                <Alert variant="danger" className="mt-3">
                    {codeError}
                </Alert>
            )}
            <hr/>
            <div style={{textAlign: "center"}}>
                Already have an account? <a href="/login">Login</a>
            </div>
        </ComponentCard>
    );
}
