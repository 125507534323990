import React from "react";
import "./Footer.css";

export default function Footer() {
    return (
        <>
            {/* <Navbar/> */}
            {/* <Link
				to='/'
				className='btn btn-primary w-10 m-3 position-absolute top-0 start-0'
			>
				Home
			</Link>
        <Link
				to='/dashboard'
				className='btn btn-primary w-10 m-3 position-absolute top-0 end-0'
			>
				Dashboard
			</Link> */}
            <div className="Footer text-bold">
                <p>
                    <br></br>
                    Check out{" "}
                    <a href="https://www.cynorix.com/" target="_blank" rel="noreferrer">
                        Cynorix.com
                    </a>
                    <br></br>
                    &copy; Cynorix 2023, Cynorix Inc. All Rights Reserved
                </p>
            </div>
        </>
    );
}
