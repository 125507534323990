import React, {useRef, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import PasswordRequirements from "../functions/PasswordReq";
import {handleLoginError} from "./Login";
import {EmailAuthProvider, reauthenticateWithCredential} from "firebase/auth";
import LoadingButton from "./LoadingButton";
import FeedbackAlert from "./FeedbackAlert";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import "./Signup.css";
import ComponentCard from "./ComponentCard";
import PasswordInput from "./PasswordInput";

library.add(faEye, faEyeSlash);

export default function ChangePassword() {
    const emailRef = useRef();
    const oldPasswordRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const {currentUser, updatePassword, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        upper: null,
        lower: null,
        specialChar: null,
    });

    const navigate = useNavigate();

    const isNumberRegx = /\d/;
    const isUpperRegx = /[A-Z]/;
    const isLowerRegx = /[a-z]/;
    const specialCharacterRegx = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const [feedback, setFeedback] = useState(null);

    const onChangePassword = () => {
        const password = passwordRef.current.value;
        // console.log(password, passwordFocused);
        setPasswordValidity({
            minChar: password.length >= 10 ? true : false,
            number: isNumberRegx.test(password) ? true : false,
            upper: isUpperRegx.test(password) ? true : false,
            lower: isLowerRegx.test(password) ? true : false,
            specialChar: specialCharacterRegx.test(password) ? true : false,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValidPassword = Object.values(passwordValidity).every((field) => field === true);
        if (!isValidPassword) {
            setFeedback({
                message: "The password you entered does not meet our security requirements. Please choose a different password and try again.",
                variant: "danger",
            });
            return;
        }

        const promises = [];
        setFeedback({
            loading: true,
            message: "Verifying your credentials...",
            variant: "info",
        });

        // reauthenticate
        const credential = EmailAuthProvider.credential(
            currentUser.email,
            oldPasswordRef.current.value
        );
        try {
            await reauthenticateWithCredential(
                currentUser,
                credential
            );
        } catch (e) {
            setFeedback({
                message: handleLoginError(e.code),
                variant: "danger",
            });
            return;
        }

        // check if the new passwords match
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            setFeedback({
                message: "Passwords do not match",
                variant: "danger",
            });
            return;
        }

        // update email and password
        if (emailRef.current.value !== currentUser.email) {
            // promises.push(updateEmail(emailRef.current.value));
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }

        Promise.all(promises)
            .then(() => {
                setFeedback({
                    loading: true,
                    message: "Changed account details! Redirecting...",
                    variant: "success",
                });
                setTimeout(() => {
                    logout();
                    navigate("/login");
                }, 3000);
            })
            .catch((err) => {
                setFeedback({
                    message: "Failed to update account",
                    variant: "danger",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const helpContent = (
        <p className="mb-1">
            By providing your old password, you can update your password on this page.
            Your new password must adhere to the security requirements, which are
            shown when you enter your new password. After filling in the information,
            you may click <b>Update</b> to submit the changes to your account. After
            successfully updating, you will need to enter your new password to log
            back in.
        </p>
    );

    return (
        <ComponentCard title="Reset Password" helpContent={helpContent}>
            <Row>
                <Col>
                    <FeedbackAlert feedback={feedback}/>
                    <Form onSubmit={handleSubmit} className="w-100 text-start">
                        <Row className="gx-1">
                            <Col lg={passwordFocused ? 9 : 12}>
                                <Form.Group controlId="email" className="mb-2 px-2 gx-2 row">
                                    <Col xs={12} sm={"auto"}>
                                        <Form.Label column style={{width: "14ch"}}>
                                            Email
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="email"
                                            ref={emailRef}
                                            required
                                            defaultValue={currentUser.email}
                                            placeholder="example@mail.com"
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="oldPassword" className="mb-2 px-2 gx-2 row">
                                    <Col xs={12} sm={"auto"}>
                                        <Form.Label column style={{width: "14ch"}}>
                                            Old Password
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <PasswordInput
                                            ref={oldPasswordRef}
                                            placeholder="Old Password"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="password" className="mb-2 px-2 gx-2 row">
                                    <Col xs={12} sm={"auto"}>
                                        <Form.Label column style={{width: "14ch"}}>
                                            New Password
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <PasswordInput
                                            ref={passwordRef}
                                            placeholder="********"
                                            onFocus={() => setPasswordFocused(true)}
                                            onBlur={() => setPasswordFocused(false)}
                                            onChange={() => onChangePassword()}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="password-confirm" className="mb-2 px-2 row gx-2">
                                    <Col xs={12} sm={"auto"}>
                                        <Form.Label column style={{width: "14ch"}}>
                                            Confirm Password
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <PasswordInput
                                            ref={passwordConfirmRef}
                                            placeholder="********"
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col>
                                {passwordFocused && (
                                    <PasswordRequirements validity={passwordValidity}/>
                                )}
                            </Col>
                        </Row>
                        <Row className="gx-2">
                            <Col>
                                <Button
                                    disabled={loading}
                                    className="w-100 mt-3"
                                    onClick={() => {
                                        navigate("/forgot-password");
                                    }}
                                >
                                    Forgot Password
                                </Button>
                            </Col>
                            <Col>
                                <LoadingButton
                                    loading={feedback && feedback.loading}
                                    className="mt-3 w-100"
                                    type="submit"
                                >
                                    Update
                                </LoadingButton>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </ComponentCard>
    );
}
