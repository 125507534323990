import React, {useState} from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import ComponentCard from "./ComponentCard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DeleteAccount() {
    const {currentUser} = useAuth();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();

    const sendDeleteAccountEmail = async (userEmail, userIndex) => {
        return await postWithCredentials(SERVER_URL + "sendDeleteAccountEmail", {
            userEmail,
            userIndex,
        })
            .then(() => {
                // console.log('Confirmation Email Sent');
            })
            .catch((err) => {
                console.log(err);
                throw new Error("Error sending confirmation email, please try again.");
            });
    };

    const handleDeleteAccount = async () => {
        try {
            // const index = generateEmailIndex(currentUser.email);
            //   setButtonDisable(true);
            await sendDeleteAccountEmail(currentUser.email, currentUser.uid);
            setSuccess("Confirmation Email Sent");
            setTimeout(() => {
                setSuccess("");
                navigate("/");
            }, 3000);
        } catch (e) {
            console.log(e);
            setError(e.message);
            setTimeout(() => {
                setError("");
            }, 2000);
        }
    };

    const helpContent = (
        <>
            <p className="mb-1">
                When you initially visit the page, it will ask you to confirm your
                intention in case you visited the page by accident. Upon clicking{" "}
                <b>Confirm</b>, it will send an email to your email inbox associated
                with your email address, which will contain a link to complete the
                process of deleting your account.
            </p>
            <p className="mb-0">
                Upon clicking the link, you will be taken to the webpage to delete your
                account. It requires you to provide your email address, your password,
                as well as a 2FA code from your phone if you set 2FA up. Once you click{" "}
                <b>Delete</b>, your account will be marked for deletion.
            </p>
        </>
    );

    return (
        <>
            <ComponentCard title="Delete Account" helpContent={helpContent}>
                <Row className="mb-3">
                    <Col>
                        If you want to delete account, please click confirm button to
                        continue. {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">{success}</Alert>}
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button className="w-100" onClick={handleDeleteAccount}>
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </ComponentCard>
        </>
    );
}
