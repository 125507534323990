import React, {useEffect, useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {auth} from "../firebase";
import {getUserFromEmail} from "../functions/getUser";
import ComponentCard from "./ComponentCard";

export default function Dashboard() {
    const [hasAccess, setHasAccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ineligible, setIneligible] = useState(false);
    useEffect(() => {
        (async () => {
            await renderAccessStatus();
        })();
    }, []);

    const navigate = useNavigate();

    const renderAccessStatus = async () => {
        try {
            const userEmail = auth.currentUser.email;
            const userData = await getUserFromEmail(userEmail);
            setHasAccess(userData.FPP !== 0 && userData.FPP !== 4);
            setIneligible(userData.FPP === 4);
            setLoading(false);
        } catch (error) {
            alert("Failed to render the page.");
        }
    };

    const helpContent = (
        <p className="mb-1">
            This page in the application, shown below, allows the user to select{" "}
            <b>Encrypt Files</b> or <b>Decrypt Files</b>. The buttons will link to the{" "}
            <b>Encrypt Files</b> and <b>Decrypt Files</b> pages respectively. You can{" "}
            <b>Encrypt Files</b> if you have a subscription or free trial active.
            Otherwise, you are only permitted to <b>Decrypt Files</b>.
        </p>
    );

    if (loading) {
        return (
            <ComponentCard title="Dashboard" helpContent={helpContent}>
                <Spinner animation="border" size="sm"/> Getting subscription
                information...
            </ComponentCard>
        );
    }

    return (
        <ComponentCard title="Dashboard" helpContent={helpContent}>
            {!hasAccess ? (
                <div>
                    <b>
                        {!ineligible
                            ? "You are not subscribed to a free trial or a subscription, you can only use our decryption service. Please purchase one of them in order to use other services."
                            : "You are not subscribed to a subscription and have used up your free trial, you can only use our decryption service. Please purchase a subscription in order to use other services."}
                        <br></br>
                        {!ineligible && (
                            <>
                                <Link to="/free-trial">Get Free Trial</Link>
                                <br></br>
                            </>
                        )}
                        <Link to="/upgrade-service">Purchase Subscription</Link>
                    </b>
                </div>
            ) : (
                ""
            )}
            <Button
                className="my-2 mt-3 w-100"
                onClick={() => {
                    navigate("/encrypt");
                }}
                disabled={!hasAccess}
            >
                Encrypt Files
            </Button>
            <Button
                className="w-100"
                onClick={() => {
                    navigate("/decrypt");
                }}
            >
                Decrypt Files
            </Button>
        </ComponentCard>
    );
}
