import React, {useState} from "react";
import Signup from "./Signup";
import {Col, Container, Navbar, Offcanvas, Row} from "react-bootstrap";
import {useAuth} from "../contexts/AuthContext";
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate,} from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import OAuth2 from "./OAuth2";
import SelectSponsor from "./SelectSponsor2";
import AcceptSponsorshipReq from "./AcceptSponsorshipReq";
import VerifyCoupon from "./VerifyCoupon";
import ServiceUsageInfo from "./ServiceUsageInfo";
import ActionPage from "./ActionPage";
import ChangePassword2 from "./ChangePassword2";
import UpgradeService from "./UpgradeService";
import Dashboard from "./Dashboard";
import CancelService from "./CancelService";
import CancelSponsorship from "./CancelSponsorship";
import CreateClass2 from "./CreateClass2";
import EditServiceClass2 from "./EditServiceClass2";
import ShowServiceClass from "./ShowServiceClass";
import EditClass2 from "./EditClass2";
import DeleteAccount2 from "./DeleteAccount2";
import CreateSubscription from "./CreateSubscription";
import Chunk from "./Chunk";
import Tryfreetrail from "./Tryfreetrail";
import Home from "./Home";
import Footer from "./Footer";
import PageNotFound from "./PageNotFound";
import RemoveAccount from "./RemoveAccount";
import Decryption2 from "./DecryptWithoutSign";

/*
Components from the non med branch
*/
import {ReactNotifications} from "react-notifications-component";
import DecryptionPageNew from "./DecryptionPageNew";
import ProfileDialog from "./ProfileDialog";
import HomePageHelp from "./HomePageHelp";
import Faq from "./Faq";
import Contact from "./Contact";
import SideBar from "./Sidebar";
import Encrypt from "./Encrypt";

/*
 */

export function getPageImage() {
    const IMG_GENERAL = "/img/CYN_Cynorix.jpg";
    const IMG_SIGNUP = "/img/CYN_Technologies.jpg";
    const IMG_SFS = "/img/CYN_sec_sharing_small.jpg";
    const IMG_2FA = "/img/CYN_Multifactor_small.jpg";
    const IMG_HELP = "/img/CYN_How_small.jpg";
    const IMG_SPONSORSHIP = "/img/CYN_Sponsor.jpg";

    switch (window.location.pathname) {
        case "/signup":
        case "/change-password":
        case "/remove-account":
        case "/verify-coupon":
            return IMG_SIGNUP;
        case "/encrypt":
        case "/decrypt":
        case "/dashboard":
            return IMG_SFS;
        case "/set-google-auth":
            return IMG_2FA;
        case "/faq":
            return IMG_HELP;
        case "/select-sponsor":
            return IMG_SPONSORSHIP;
        default:
            return IMG_GENERAL;
    }
}

export default function App() {
    const [showDrawer, setShowDrawer] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const {currentUser} = useAuth();

    const isUsageInfoPage = location.pathname === "/service-usage-info";
    const isHomePage = location.pathname === "/" || location.pathname === "/home";

    const maintenanceEmail =
        process.env.REACT_APP_MAINTENANCE_EMAIL || "maintenance@cynorix.com";
    const isUserMaintenance =
        !!currentUser && currentUser.email === maintenanceEmail;

    const ProtectedRoute = ({isAllowed, redirectPath, children}) => {
        if (isAllowed) {
            return <Outlet/>;
        }

        return children;
    };

    const RequireSignInRoute = () => {
        return (
            <ProtectedRoute isAllowed={currentUser !== null}>
                <Navigate to="/login"/>
            </ProtectedRoute>
        );
    };

    const NotMaintenanceRoute = () => {
        return (
            <ProtectedRoute isAllowed={!isUserMaintenance}>
                <Navigate to={"/maintenance-dashboard"}/>
            </ProtectedRoute>
        );
    };

    const MaintenanceRoute = () => {
        return (
            <ProtectedRoute isAllowed={isUserMaintenance}>
                <PageNotFound/>
            </ProtectedRoute>
        );
    };

    return (
        <div style={{minHeight: "100vh", position: "relative"}}>
            {/* Background Image */}
            <div
                style={{
                    // TODO: create a url map to the different page urls
                    backgroundImage: `url(${getPageImage()})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "fixed",
                    height: "100vh",
                    width: "100vw",
                    filter: "blur(10px) opacity(15%)",
                    display: isHomePage ? "none" : null,
                }}
            />

            <Navbar className="px-3 bg-white" expand="false">
                <Navbar.Brand className="p-2">
                    <img
                        src="/images/CYN_logo.png"
                        width={203}
                        alt="cynorix logo"
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle
                    className={isUsageInfoPage ? null : "d-xl-none"}
                    placement="end"
                    onClick={() => {
                        setShowDrawer(true);
                    }}
                    style={{
                        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                    }}
                />
            </Navbar>
            <Container
                fluid
                className="py-3 px-xs-0 px-md-5"
                style={{
                    display:
                        location.pathname === "/" || location.pathname === "/home"
                            ? "none"
                            : null,
                }}
            >
                <Row className="px-xs-0 px-md-5" style={{flexWrap: "nowrap"}}>
                    {/* Page Contents */}
                    <Col className="px-2" style={{width: "100%"}}>
                        <ReactNotifications/>
                        <Routes>
                            <Route element={<RequireSignInRoute/>}>
                                <Route element={<NotMaintenanceRoute/>}>
                                    <Route
                                        exact
                                        path="/dashboard"
                                        element={<Dashboard user={currentUser}/>}
                                    />
                                    <Route path="/chunk" element={<Chunk user={currentUser}/>}/>
                                    <Route
                                        path="/create-subscription"
                                        element={<CreateSubscription user={currentUser}/>}
                                    />
                                    <Route
                                        path="/select-sponsor"
                                        element={<SelectSponsor user={currentUser}/>}
                                    />
                                     <Route path="/verify-coupon" element={<VerifyCoupon />} />

                                    <Route
                                        path="/accept-sponsorship-req"
                                        element={<AcceptSponsorshipReq user={currentUser}/>}
                                    />
                                    <Route
                                        path="/cancel-service"
                                        element={<CancelService user={currentUser}/>}
                                    />
                                    <Route
                                        path="/cancel-sponsorship"
                                        element={<CancelSponsorship user={currentUser}/>}
                                    />
                                    <Route
                                        path="/service-usage-info"
                                        element={<ServiceUsageInfo user={currentUser}/>}
                                    />
                                    <Route
                                        path="/action"
                                        element={<ActionPage user={currentUser}/>}
                                    />
                                    <Route
                                        path="/show-service-class"
                                        element={<ShowServiceClass user={currentUser}/>}
                                    />
                                    <Route
                                        path="/free-trial"
                                        element={<Tryfreetrail user={currentUser}/>}
                                    />
                                    <Route exact path="/encrypt" element={<Encrypt/>}/>
                                    <Route
                                        exact
                                        path="/payments"
                                        element={<ProfileDialog user={currentUser}/>}
                                    />
                                </Route>
                                <Route element={<MaintenanceRoute/>}>
                                    <Route
                                        path="/maintenance-dashboard"
                                        element={<EditServiceClass2 user={currentUser}/>}
                                    />
                                    <Route
                                        path="/edit-service-class"
                                        element={<EditServiceClass2 user={currentUser}/>}
                                    />
                                    <Route
                                        path="/create-class"
                                        element={<CreateClass2 user={currentUser}/>}
                                    />
                                    <Route
                                        path="/edit-class/:classId"
                                        element={<EditClass2 user={currentUser}/>}
                                    />
                                </Route>
                                <Route
                                    path="/change-password"
                                    element={<ChangePassword2 user={currentUser}/>}
                                />
                                <Route
                                    path="/set-google-auth"
                                    element={<OAuth2 user={currentUser}/>}
                                />
                            </Route>
                            <Route
                                exact
                                path="/decrypt"
                                element={<DecryptionPageNew user={currentUser}/>}
                            />
                            <Route path="/signup" element={<Signup/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                            <Route
                                path="/delete-account/:userId"
                                element={<DeleteAccount2/>}
                            />
                            <Route path="/remove-account" element={<RemoveAccount/>}/>
                            <Route path="/upgrade-service" element={<UpgradeService/>}/>
                            <Route exact path="/help" element={<HomePageHelp/>}/>
                            <Route exact path="/faq" element={<Faq/>}/>
                            <Route exact path="/contact" element={<Contact/>}/>

                            {/* <Route exact path="/encrypt" render={this.checkLogin} /> */}
                            <Route exact path="/decrypt2" element={<Decryption2/>}/>

                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>
                    </Col>

                    {/* Sidebar */}
                    {["/home", "/", "/decrypt2"].some(
                        (item) => location.pathname === item
                    ) ? null : (
                        <Col
                            className="px-2 d-none d-xl-inline"
                            xs={12}
                            style={{flexBasis: 350, flexShrink: 1}}
                        >
                            <Offcanvas
                                show={showDrawer}
                                onHide={() => {
                                    setShowDrawer(false);
                                }}
                                responsive={isUsageInfoPage ? null : "lg"}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Navigation</Offcanvas.Title>
                                </Offcanvas.Header>
                                <SideBar/>
                            </Offcanvas>
                        </Col>
                    )}
                </Row>
            </Container>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/home" element={<Home/>}/>
            </Routes>
            <div style={{height: 50}}/>
            <Footer/>
        </div>
    );
}
