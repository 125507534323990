import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBImU0FuYCarxovboqnHmrkoxlW4Qj7Pns",
    authDomain: "payment-sys-demo.firebaseapp.com",
    databaseURL: "https://payment-sys-demo-default-rtdb.firebaseio.com",
    projectId: "payment-sys-demo",
    storageBucket: "payment-sys-demo.appspot.com",
    messagingSenderId: "550950481336",
    appId: "1:550950481336:web:127a4f1db04a195d32334d"
  };
  
const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
