import React from 'react';
import {Button, Modal} from 'react-bootstrap';

export default function TextModal(props) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button
                    style={{
                        backgroundColor: '#800000',
                        color: 'white',
                        border: 'black',
                    }}
                    onClick={props.handleClose}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
