import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {auth} from "../firebase";
import {getUserFromEmail} from "../functions/getUser";
import {Button, Card, Col, Row} from "react-bootstrap";
import BannerImage from "./BannerImage";
import NotFound from "./PageNotFound";

export default function EditServiceClass() {
    const [productCount, setProductCount] = useState(0);
    const [productsInfo, setProductsInfo] = useState([]);
    const [hasAccess, setHasAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // check access before proceeding
        const userEmail = auth.currentUser.email;
        if (userEmail !== "maintenance@cynorix.com") return;
        setHasAccess(true);

        (async () => {
            try {
                const userEmail = auth.currentUser.email;
                const userData = await getUserFromEmail(userEmail);
                const products = userData.products;

                if (products) {
                    // get number of existing classes
                    setProductCount(Object.keys(products).length);
                    // Render each existing class name as button
                    const listOfProducts = [];
                    for (const product in products) {
                        listOfProducts.push({[product]: products[product]});
                    }
                    setProductsInfo(listOfProducts);
                }
            } catch (error) {
                alert("Error Rendering Page");
                navigate("/maintenance-dashboard");
            }
        })();
    }, [navigate]);

    return (
        <>
            {hasAccess && (
                <>
                    <Card
                        style={{
                            border: "none",
                            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                        }}
                    >
                        <Card.Body>
                            <BannerImage url="/img/CYN_Cynorix.jpg"/>
                            <h4 className="mt-3">
                                <b>Edit Subscription Tiers</b>
                            </h4>
                            <hr className="mt-0"/>
                            <h4 className="mb-1 d-flex justify-content-center">
                                Existing Subscription Tiers: {productCount}
                            </h4>
                            <Row>
                                {productsInfo.map((product) => {
                                    const className = Object.values(product)[0].subscription_name;
                                    const id = Object.keys(product)[0];
                                    return (
                                        <Col md={6} sm={12}>
                                            <Link
                                                to={{
                                                    pathname: `/edit-class/${id}`,
                                                    state: {id},
                                                }}
                                                key={id}
                                                className="btn btn-outline-secondary w-100 mt-3"
                                            >
                                                {className}
                                            </Link>
                                        </Col>
                                    );
                                })}
                                <hr
                                    style={{
                                        height: "3px",
                                        backgroundColor: "white",
                                        color: "white",
                                        width: "96%",
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        navigate("/create-class");
                                    }}
                                    variant="danger"
                                    style={{
                                        backgroundColor: "#800000",
                                        border: "none",
                                    }}
                                >
                                    Add Subscription Tier
                                </Button>
                            </Row>
                        </Card.Body>
                    </Card>
                </>
            )}
            {!hasAccess && <NotFound/>}
        </>
    );
}
