import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";

export default function FeedbackProgressBar(props) {
  const [downloadProgress, setDownloadProgress] = useState(props.downloadProgress);
  useEffect(() => {
    setDownloadProgress(props.downloadProgress);
  }, [props.downloadProgress]);

  if (downloadProgress === null) return null;

  return (
    <>
        {downloadProgress.loading === true ? (
        <ProgressBar 
            style={props.style}
            className={props.className}
            variant="danger"
            now={Math.min(downloadProgress.now, 100)}
            label={`${Math.min(downloadProgress.now, 100)}%`}
            animated
        />
        ) : null}
    </>
  );
}
