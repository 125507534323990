import axios from "axios";
import {useEffect, useState} from "react";
import {Accordion, Button, Card, Image, ListGroup} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {getUserFromEmail} from "../functions/getUser";
import "./SideBar.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const accountDropdownOptions = [
    {
        name: "Reset Password",
        link: "/change-password",
    },
    {
        name: "Register 2-Factor Authentication",
        link: "/set-google-auth",
    },
    {
        name: "Delete Account",
        link: "/remove-account",
    },
    {
        name: "Verify Coupon",
        link: "/verify-coupon",
      },
];

export const PLAN_STATE_FT_EXPIRED = 4;
export const PLAN_STATE_FT_ELIGIBLE = 0;
export const PLAN_STATE_FT_ACTIVE = 3;
export const PLAN_STATE_PAID = 1;
export const PLAN_STATE_PAID_SPONSOR = 2;

const subscriptionDropdownOptions = [
    {
        name: "My Service Usage",
        link: "/service-usage-info",
        cond: ({payLevel}) =>
            [PLAN_STATE_PAID, PLAN_STATE_PAID_SPONSOR, PLAN_STATE_FT_ACTIVE].includes(
                payLevel
            ),
    },
    {
        name: "Change Subscription",
        link: "/upgrade-service",
        cond: ({payLevel}) =>
            payLevel === PLAN_STATE_PAID || payLevel === PLAN_STATE_PAID_SPONSOR,
    },
    {
        name: "Purchase Subscription",
        link: "/upgrade-service",
        cond: ({payLevel}) =>
            [
                PLAN_STATE_FT_EXPIRED,
                PLAN_STATE_FT_ELIGIBLE,
                PLAN_STATE_FT_ACTIVE,
            ].includes(payLevel),
    },
    {
        name: "Free Trial",
        link: "/free-trial",
        cond: ({payLevel}) => payLevel === PLAN_STATE_FT_ELIGIBLE,
    },
    {
        name: "Sponsorship Requests",
        link: "/accept-sponsorship-req",
        cond: ({payLevel}) => payLevel === PLAN_STATE_PAID,
    },
    {
        name: "Buy Chunks",
        link: "/chunk",
        cond: ({payLevel, chunkEnable}) =>
            payLevel === PLAN_STATE_PAID && chunkEnable,
    },
    {
        name: "Cancel Service",
        link: "/cancel-service",
        cond: ({payLevel}) => payLevel === PLAN_STATE_PAID,
    },
    {
        name: "Cancel Sponsorship",
        link: "/cancel-sponsorship",
        cond: ({payLevel}) => payLevel === PLAN_STATE_PAID_SPONSOR,
    },
];

const fileSharingListOptions = [
    {
        name: "Dashboard",
        link: "/dashboard",
    },
    {
        name: "Encrypt Files",
        link: "/encrypt",
    },
    {
        name: "Decrypt Files",
        link: "/decrypt",
    },

    {
        name: "Help",
        link: "/faq",
    },
];

const notSignedInListOptions = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "Decrypt Files",
        link: "/decrypt2",
    },
    {
        name: "Help",
        link: "/faq",
    },
];

export default function SideBar(props) {
    // get current location
    const location = useLocation();
    const navigate = useNavigate();
    const maintenanceEmail = process.env.REACT_APP_MAINTENANCE_EMAIL;
    // user
    const [servicePlan, setServicePlan] = useState(PLAN_STATE_FT_ELIGIBLE);
    const [chunkEnable, setChunkEnable] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    // get login and logout functions
    const {currentUser, logout} = useAuth();

    useEffect(() => {
        console.log("test");
        if (currentUser) {
            const email = currentUser.email;
            getUserFromEmail(email).then((data) => {
                setUserInfo(data);
            });
        } else {
            setUserInfo(null);
        }
    }, [currentUser]);

    useEffect(() => {
        // perform a check if account is locked
        // also more options if the user is paid
        function checkServiceStatus() {
            // Post to server retrieve questions from database
            setServicePlan(userInfo.FPP);

            if (userInfo.FPP === PLAN_STATE_PAID) {
                postWithCredentials(SERVER_URL + "check-limit-requirement", {
                        userData: userInfo,
                    })
                    .then((res) => {
                        let serviceInfo = res.data.info[0];
                        // console.log("SERVICE INFO", serviceInfo);
                        if (
                            serviceInfo.service_limit <= serviceInfo.used_limit &&
                            serviceInfo.allow_to_use === 0
                        ) {
                            setChunkEnable(true);
                        }
                    });
            }
        }

        if (userInfo !== null) {
            checkServiceStatus();
        }
    }, [userInfo, location]);


    if (userInfo === null || userInfo === undefined) {
        return (
            <Card>
                <Card.Body>
                    <Button
                        variant="light"
                        style={{width: "100%", textAlign: "left", padding: 17}}
                        // disabled
                        onClick={() => {
                            navigate("/dashboard");
                        }}
                    >
                        <h5 className="m-0">Not Signed In</h5>
                    </Button>
                    <hr/>
                    {navListGroup(notSignedInListOptions)}
                </Card.Body>
            </Card>
        );
    }

    function navListGroup(navListItems) {
        const listItems = [];
        if (location.pathname === "/set-password") return null;

        for (const navListItem of navListItems) {
            const {name, link, cond} = navListItem;
            if (!cond || cond({payLevel: servicePlan, chunkEnable})) {
                const isHere = link === location.pathname;
                listItems.push(
                    <ListGroup.Item
                        key={link}
                        action
                        active={isHere}
                        style={{
                            border: "none",
                            borderRadius: 5,
                            fontWeight: isHere ? "bold" : "normal",
                            textAlign: "left",
                        }}
                        onClick={() => {
                            if (userInfo && userInfo.email === maintenanceEmail) {
                                navigate("/maintenance-dashboard");
                            } else {
                                navigate(link);
                            }
                        }}
                    >
                        {name}
                    </ListGroup.Item>
                );
            }
        }

        return (
            <ListGroup
                className="p-1 py-2"
                style={{padding: 5, border: "1px solid #d9d9d9"}}
            >
                {listItems}
            </ListGroup>
        );
    }

    return (
        <Card body>
            {/* Profile Dropdown */}
            <Accordion>
                <Accordion.Item eventKey="user">
                    <Accordion.Header>
                        <Image
                            referrerPolicy="no-referrer"
                            roundedCircle
                            src={userInfo.imageUrl}
                            style={{
                                height: 30,
                                marginRight: 10,
                            }}
                        />
                        <ul className="list-unstyled m-0">
                            <li>
                                <h5 className="m-0">
                                    {userInfo.first_name} {userInfo.last_name}
                                </h5>
                            </li>
                            <li>
                                <h5 className="m-0 small text-muted">{userInfo.email}</h5>
                            </li>
                            {servicePlan === PLAN_STATE_FT_ACTIVE ? (
                                <li className=" small">
                                    <hr className="my-1"></hr>
                                    Free Trial
                                </li>
                            ) : null}
                        </ul>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                        <div className="p-2">
                            <Accordion
                                alwaysOpen
                                style={{
                                    borderRadius: 5,
                                    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <Accordion.Item eventKey="my-account">
                                    <Accordion.Header>My Account</Accordion.Header>
                                    <Accordion.Body className="p-2">
                                        {navListGroup(accountDropdownOptions)}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="my-sub">
                                    <Accordion.Header>My Subscription</Accordion.Header>
                                    <Accordion.Body className="p-2">
                                        {navListGroup(subscriptionDropdownOptions)}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <hr className="my-0"/>
                        <div className="p-2">
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    // props.signOut();
                                    logout();
                                    navigate("/");
                                }}
                                style={{width: "100%"}}
                            >
                                Sign Out
                            </Button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <hr/>
            {/* Nav links */}
            {navListGroup(fileSharingListOptions)}
            <hr/>
            <Button
                variant="secondary"
                style={{width: "100%"}}
                onClick={() => {
                    navigate(-1);
                }}
            >
                Go Back
            </Button>
        </Card>
    );
}
