import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
library.add(faCircleInfo);

export default function HelpButton(props) {
  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>
            {props.header ? (
              <>
                <h6>
                  <b>{props.header}</b>
                </h6>
                <hr className="my-0 mb-2" />
              </>
            ) : null}
            {props.children}
          </Popover.Body>
        </Popover>
      }
    >
      <FontAwesomeIcon className="mx-1" icon="circle-info"/>
    </OverlayTrigger>
  );
}
